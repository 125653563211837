html{
  height: 100vh;
}

body{
  height: 100%;
  background-color: #f5f5f5;
}

#root {
  height: 100%;
}